import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { Section, SectionWrapper, Title, Text } from "../../core/commonExports"

const reviewData = [
  {
    reviewer: "Vishal Ahuja",
    reviewerDP: <StaticImage 
      src="../../../assets/images/campaigns/review-imgs/vishal-ahuja.webp"
      alt="vishal-ahuja"
      loading="lazy"
    />,
    info: "a month ago | 3 Reviews",
    reviewText: (
      <>
        I have my mobile shop business in topi bazar Gwalior (MP). Our entire area is full of clothing, shoes, mobile and fast food stores. With such a wide variety of shops, there is even a greater amount of risk involved. What will happen if something accidentally happens in nearby shop. Hence, it’s always better to be protected that’s why I recently bought business insurance policy with Verak and it’s been an amazing experience. Their team is very supportive and continuously follows up. Highly recommended!
        <br /><br />
        Couldn't be happier.
      </>
    ),
  },
  {
    reviewer: "Anuj Gupta",
    reviewerDP: <StaticImage 
      src="../../../assets/images/campaigns/review-imgs/anuj-gupta.webp"
      alt="anuj-gupta"
      loading="lazy"
    />,
    info: "4 months ago | 2 Reviews | 4 Photos",
    reviewText: "I was looking to insure my business for a long time but was confused about the procedure and coverage. Then this Verak team approached me. Even though the entire purchase was done online, I found the process very transparent and the staff very supportive. I ended up buying it at an affordable price and that too with extra benefits. It was totally worth it!",
  },
]

const ReviewItem = ({reviewData = {}}) => {
  const {info, reviewer, reviewerDP, reviewText} = reviewData
  return (
    <ReviewCard>
      <ReviewHeader>
        <ReviewMetaInfo>
          <ReviewerProfilePicture>
            {reviewerDP}
          </ReviewerProfilePicture>
          <section>
            <section className="d-flex">
              <Text fontSize="16px" mfontSize="14px" lineHeight="17px" fontWeight="500" color="rgba(0, 0, 0, 0.87)" style={{margin: "0", marginRight: "0.25rem"}}>{reviewer}</Text>
              <div style={{width: "78px", height: "14px"}}>
                <StaticImage 
                  src="../../../assets/images/campaigns/review-imgs/google-review-5-stars.webp"
                  alt="5-stars"
                  loading="lazy"
                  placeholder="blurred"
                />
              </div>
            </section>
            <Text fontSize="14px" mfontSize="12px" lineHeight="19px" color="rgba(0, 0, 0, 0.54)" fontWeight="400" style={{marginTop: '0', display: "flex"}}>
              {info}
            </Text>
          </section>
        </ReviewMetaInfo>
        <div style={{width: "24px"}}>
          <StaticImage 
            src="../../../assets/images/campaigns/review-imgs/google.webp"
            alt="google-review"
            loading="lazy"
            placeholder="blurred"
          />
        </div>
      </ReviewHeader>
      <ReviewText>
        {reviewText}
      </ReviewText>
    </ReviewCard>
  )
}

const ReviewSection = ({sectionBg = "#fff"}) => {
  return (
    <Section background={sectionBg}>
      <SectionWrapper>
        <Title fontSize="32px" mfontSize="22px" lineHeight="38px" mlineHeight="32px" fontWeight="bold" color="#2A2B7F" style={{textAlign: "center", textTransform: "uppercase", marginBottom: "48px"}}>
          Join 5000+ businesses that rely on us for protection!
        </Title>
        <ReviewsContainer>
          {reviewData.map(review => <ReviewItem reviewData={review} />)}
        </ReviewsContainer>
      </SectionWrapper>
    </Section>
  );
}

export default ReviewSection

const ReviewsContainer = styled.section`
  width: fit-content;
  margin: auto;
`

const ReviewCard = styled.article`
  padding: 1.5rem;
  margin: auto;
  margin-bottom: 1rem;
  width: min(780px, 100%);
  min-height: 310px;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
  border-radius: 12px;

  @media screen and (max-width: 768px) {
    padding: 0.5rem;
    borde-radius: 8px;
  }
`

const ReviewHeader = styled.section`
  display: flex;
  justify-content: space-between;
`

const ReviewMetaInfo = styled(ReviewHeader)`
  gap: 0.83rem;

  .d-flex {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  @media screen and (max-width: 768px) {
    .d-flex {
      margin-bottom: 0.25rem;
    }
  }
`

const ReviewerProfilePicture = styled.section`
  width: 40px;
`

const ReviewText = styled.section`
  margin-left: 54px;
  margin-right: 27px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #2A363D;
  text-align: left;

  @media screen and (max-width: 768px){
    margin-left: 24px;
    margin-right: 4px;
    font-size: 14px;
  }
`